import { ShoppingCartProducts } from '../store/reducers/CartReducer';
import { ProductNode } from '../interfaces/contentful';

export const calculateTotalNumber = (products: ShoppingCartProducts): number => {
  return Object.values(products).reduce((acc, product) => acc + product.quantity, 0);
};

export const normaliseProductPayload = (productsPayload: { edges: { node: ProductNode }[] }) => {
  return Object.values(productsPayload.edges).map((product) => {
    return {
      ...product.node,
    };
  });
};
