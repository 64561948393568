import { CategoryNode, CategoryNodeTree } from '../interfaces/contentful';

export const findCategoryBySlugInCategoryTree = (
  slug: string,
  categoryTree: CategoryNodeTree[],
): CategoryNodeTree | null => {
  let foundCat: CategoryNodeTree | null = null;

  function recurseTree(tree: CategoryNodeTree[]) {
    for (const cat of tree) {
      if (foundCat) {
        return;
      }

      if (cat.slug === slug) {
        foundCat = cat;
        break;
      }

      recurseTree(cat.childNodes);
    }
  }

  recurseTree(categoryTree);

  return foundCat;
};

/**
 * Normalising the messy Contentful and Gatsby payload from this form
 *   "edges": [
 *     {
 *       "node": {
 *         "slug": "roggebrood",
 *         "title": "Roggebrood",
 *         "contentful_id": "gUpuoITZPUv2KXpkvTlFt",
 *         "parentCategory": {
 *           "slug": "brood",
 *           "contentful_id": "78iC2OvXKYXO5zAnzbker5"
 *         },
 *         "regionalproduct": [{ "contentful_id": "5rrAxCuBXbZkb6o5HCr4z9"}]
 *       }
 *     },
 *     {
 *       "node": {
 *         "slug": "knackebrood",
 *         "title": "Knackebrood",
 *         "contentful_id": "7AvhFIBOMTZppVPNprqnBt",
 *         "parentCategory": {
 *           "slug": "brood",
 *           "contentful_id": "78iC2OvXKYXO5zAnzbker5"
 *         },
 *         "regionalproduct": [ {"contentful_id": "6GbVOc1givmpfL5ym749IH"},{"contentful_id": "7hvsD5VfPszxUnOo5bkhry" }}]
 *       }
 *     },
 * ]
 *
 * TO the payload below (removes "edge" and "node" intermediate key/object)
 *
 * [
 *   {
 *     "slug": "roggebrood",
 *     "title": "Roggebrood",
 *     "contentful_id": "gUpuoITZPUv2KXpkvTlFt",
 *     "parentCategory": {
 *       "slug": "brood",
 *       "contentful_id": "78iC2OvXKYXO5zAnzbker5"
 *     },
 *     "regionalproduct": [ { "contentful_id": "5rrAxCuBXbZkb6o5HCr4z9" } ]
 *   },
 *   {
 *     "slug": "knackebrood",
 *     "title": "Knackebrood",
 *     "contentful_id": "7AvhFIBOMTZppVPNprqnBt",
 *     "parentCategory": {
 *       "slug": "brood",
 *       "contentful_id": "78iC2OvXKYXO5zAnzbker5"
 *     },
 *     "regionalproduct": [ { "contentful_id": "6GbVOc1givmpfL5ym749IH" }, { "contentful_id": "7hvsD5VfPszxUnOo5bkhry" }, { "contentful_id": "3RsWTABsRB8lioJrh0ZpXs" } ]
 *   },
 *   .....
 * ]
 *
 * @param categoriesPayload
 */
export const normaliseCategoriesPayload = (categoriesPayload: { edges: { node: CategoryNode }[] }) => {
  return Object.values(categoriesPayload.edges).map((category) => {
    return {
      ...category.node,
    };
  });
};

/**
 * Creates hash table where slug is a key, parentSlug is added and childNodes array always empty at the beginning
 *
 * "nodes": [
 *   roggebrood: {
 *     title: 'Roggebrood',
 *     slug: 'roggebrood',
 *     parentSlug: 'brood',
 *     childNodes: [],
 *     parentCategory: {
 *       slug: 'brood',
 *       title: 'Brood',
 *     }
 *   },
 *   knackebrood: {
 *     title: 'Knackebrood',
 *     slug: 'knackebrood',
 *     parentSlug: 'brood',
 *     childNodes: [],
 *     parentCategory: {
 *       slug: 'brood',
 *       title: 'Brood',
 *     }
 *   },
 *   ....
 * ]
 */
export const createBySlugHash = <T extends CategoryNode>(records: T[]): { [key: string]: CategoryNodeTree } => {
  return records.reduce((acc: Record<string, CategoryNodeTree>, category) => {
    acc[category.slug] = {
      ...category,
      parentSlug: category.parentCategory ? category.parentCategory.slug : null,
      childNodes: [],
    };

    return acc;
  }, {});
};

/**
 * Builds the category tree structure using the intermediate "categories by slug" hash table.
 *
 * @see other implementations https://stackoverflow.com/questions/18017869/build-tree-array-from-flat-array-in-javascript, https://gist.github.com/ggondim/35376795cb832103e466fc158db74af4
 */
export const createCategoryTree = (
  nodes: { edges: { node: CategoryNode }[] },
  normalizer?: (arg: typeof nodes) => CategoryNode[],
) => {
  const normalisedCategoryNodes = normalizer ? normalizer(nodes) : normaliseCategoriesPayload(nodes);
  const categoryListBySlug = createBySlugHash(normalisedCategoryNodes);

  // It can go all in one iteration because the whole Category object is being adjusted and copied by reference.
  const categoriesTree: CategoryNodeTree[] = [];
  Object.values(categoryListBySlug).forEach((cat) => {
    if (cat.parentSlug) {
      categoryListBySlug[cat.parentSlug].childNodes.push(categoryListBySlug[cat.slug]);
    } else {
      categoriesTree.push(categoryListBySlug[cat.slug]);
    }
  });

  return categoriesTree;
};

export const createFlatCategoryList = (nodes: { edges: { node: CategoryNode }[] }): string[] => {
  return nodes.edges.map(({ node }) => node.slug);
};

export const isCategorySlugAllowed = (catSlug: string | null, nodes: { edges: { node: CategoryNode }[] }): boolean => {
  const allowedCategories = createFlatCategoryList(nodes);

  return catSlug !== null && allowedCategories.includes(catSlug);
};
