import React from 'react';
import Badge from '@material-ui/core/Badge';
import { Theme, withStyles, createStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';

const StyledBadge = withStyles((theme: Theme) =>
  createStyles({
    badge: {
      right: -3,
      top: 13,
      // border: `2px solid ${theme.palette.background.paper}`,
      border: `2px solid red`,
      marginRight: '2px',
      padding: '0 4px',
    },
  }),
)(Badge);

interface ShoppingCartBadgeProps {
  quantity: number;
}

export const ShoppingCartBadge: React.FC<ShoppingCartBadgeProps> = ({ quantity }) => (
  <IconButton aria-label="cart" style={{ marginRight: '5px' }}>
    <StyledBadge badgeContent={quantity} color="secondary">
      <ShoppingCartIcon />
    </StyledBadge>
  </IconButton>
);
